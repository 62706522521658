import React, { useCallback, useEffect, useState } from 'react'
import supabase from '../../utils/supabase';
import { v4 as uuidv4 } from 'uuid';
import { SubmitHandler, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import _ from 'lodash'
import { ErrorMessage } from '@hookform/error-message';





interface User {
    id: number,
    name: string,
    role: string,
    email: string,
    last_sign_in_at: string
    code_firstaccess: number | null
}

interface NewUserFormInput {
    name: string,
    password: string,
    role: string,
    email: string,
    code: string
}

const UsersPage = () => {

    const [users, setUsers] = useState<User[]>([]);
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState(10);
    const [filter, setFilter] = useState('');
    const [totalRecords, setTotalRecords] = useState<number>(0)
    const [showNewModal, setShowNewModal] = useState<boolean>(false)
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)


    const { register, handleSubmit, formState: { errors } } = useForm<NewUserFormInput>(); //register è una callback function che salva gli input nell'hook e ritorna alcune props, 



    const debouncedFetchAccounts = useCallback(
        _.debounce((currentFilter,currentPage, currentPageSize) => {
            fetchData(currentFilter,currentPage, currentPageSize);
            
        }, 500),
        []
    );


    useEffect(() => {


        fetchCount(filter);
        debouncedFetchAccounts(filter, page, pageSize);

    }, [page, pageSize, filter, debouncedFetchAccounts])




    const fetchData = async (filterData: string, pageData: number, pageSizeData: number) => {
        try {
            const { data, error } = await supabase.from('adminusers').select('*').ilike('email', `%${filterData}%`).range((page - 1) * pageSize, page * pageSize - 1).order('id');

            if (data) {
                console.log("dati dal db", data)
                setUsers(data);

                return

            } else {
                console.log("Dati degli user non presenti")
                throw error;
            }

        }
        catch (error) {
            console.log("Error accounts message:", error);
        }



    }

    const fetchCount = async (filterData: string) => {
        const { count, error } = await supabase.from('adminusers').select('*', { count: 'exact', head: true }).ilike('email', `%${filterData}%`)
        if (error) {
            console.error(error)
        } else if (count !== null) {
            setTotalRecords(count)
        }
    }

    const totalPages = (totalRecords === 0) ? 1 : Math.ceil(totalRecords / pageSize)

    const showModalNewUser = () => {
        setShowNewModal(true);
    }

    const acceptNewUser: SubmitHandler<NewUserFormInput> = async (data: { name: string, email: string, role: string, code: string }) => {

        const newUuid = uuidv4();
        const now = dayjs()


        const { data: supabaseData, error } = await supabase.from('adminusers').insert([{ id: newUuid, name: data.name, email: data.email, role: data.role, is_sso_user: false, is_anonymous: false, confirmed_at: now, is_firstaccess: false, code_firstaccess: data.code },]).select()


        if (error) {

            alert("Errore durante l'update")
        } else {
            alert("Utente creato!");
            console.log("data update", supabaseData);
            fetchData(filter,page, pageSize);
        }
        setShowNewModal(false);
    }


    const closeModalNewUser = () => {
        setShowNewModal(false);
    }

    const handleDeleteUser = async () => {
        if (!selectedUser)
            return;

        const { data: userData, error } = await supabase.from('adminusers').select('*').eq('email', selectedUser.email);

        if (error || userData === undefined) {
            console.log("error", error);
            alert("Errore durante la ricerca dell'utente")
        } else {

            const { error: deletePublicError } = await supabase.from('adminusers').delete().eq('email', selectedUser.email);

            if (deletePublicError) {
                alert("Errore durante la cancellazione dell'utente");
            } else {
                alert("Utente cancellato!");
                fetchData(filter,page, pageSize);
            }
        }
        closeModalDeleteUser();
    }

    const closeModalDeleteUser = () => {
        setSelectedUser(null);
        setShowDeleteModal(false);
    }

    const showModalDeleteUser = (user: User) => {
        setSelectedUser(user);
        setShowDeleteModal(true);
    }


    return (
        <div className='relative pt-24 shadow-md sm:rounded-lg w-full'>

            <div className="flex items-center justify-between flex-col border border-stroke bg-gray-50 px-5 pt-6 pb-2.5 shadow-default sm:px-7.5 xl:pb-1 w-full ">
                <div className='flex justify-between  mt-4 w-full px-4 container'>
                    <div className=''>
                        <input
                            type="text"
                            placeholder="Email..."
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                            className="px-4 py-2 border rounded w-full"
                        />
                        
                    </div>
                    <div className='flex'>
                        <button className='px-4 py-2 bg-rifiutando-green hover:bg-rifiutando-greenhover text-white  font-sans rounded' onClick={() => showModalNewUser()}>Nuovo</button>
                    </div>

                </div>
                <div className="w-full overflow-x-auto pb-24 container">
                    <table className='table-auto w-full text-sm text-left rtl:text-right text-gray-500 mb-12'>
                        <thead className='text-md uppercase text-gray-700 bg-gray-50 min-w-full max-w-full'>
                            <tr>
                                <th scope='col' className="px-6 py-3 font-sans">id</th>
                                <th scope='col' className="px-6 py-3 font-sans">name</th>
                                <th scope='col' className="px-6 py-3 font-sans">role</th>
                                <th scope='col' className="px-6 py-3 font-sans">email</th>
                                <th scope='col' className="px-6 py-3 font-sans">last_sign_in_at</th>
                                <th scope='col' className="px-6 py-3 font-sans">code_firstaccess</th>
                                <th scope='col' className="px-6 py-3 font-sans">delete</th>
                            </tr>
                        </thead>
                        <tbody className="overflow-y-auto">

                            {users.map((user) => (
                                <tr key={user.id} className="bg-white hover:bg-gray-100 border-b ">
                                    <td className="px-6 py-4 font-sans">{user.id}</td>
                                    <td className="px-6 py-4 font-sans">{user.name}</td>
                                    <td className="px-6 py-4 font-sans">{user.role}</td>
                                    <td className="px-6 py-4 font-sans">{user.email}</td>
                                    <td className="px-6 py-4 font-sans">{user.last_sign_in_at}</td>
                                    <th scope='col' className="px-6 py-3 font-sans">{user.code_firstaccess}</th>
                                    <td className="px-6 py-4"><button type='button' onClick={() => showModalDeleteUser(user)} className='px-4 py-2 text-white rounded bg-gray-500 hover:bg-gray-700'><i className="fa-solid fa-trash-can"></i></button></td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
                <div className='flex justify-between items-center mt-4 mb-4 w-full px-4 container'>
                    <div className='flex items-center '>
                        <span className='mr-2  font-sans'>Righe per pagina:</span>
                        <select value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))} className="px-4 py-2 border rounded">
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </div>
                    <div className='flex items-center gap-x-2'>
                        <button className="px-4 py-2 bg-gray-300  font-sans rounded" onClick={() => setPage(page - 1)} disabled={page === 1}>Precedente</button>
                        <span className=' font-sans'>Pagina {page} di {totalPages}</span>
                        <button className="px-4 py-2 bg-gray-300  font-sans rounded" onClick={() => setPage(page + 1)} disabled={page >= totalPages} >Successivo</button>
                    </div>
                </div>


                {showNewModal && (
                    <div className='fixed w-full inset-0 flex items-center justify-center bg-black bg-opacity-50'>
                        <div className='bg-white p-4 rounded'>
                            {/*Modal header*/}
                            <div className='flex items-start justify-between p-4 pb-1 border-b rounded-t '>
                                <h3 className='text-2xl font-bold mb-6 text-center  font-sans'>
                                    Nuovo utente amministratore
                                </h3>
                            </div>
                            <form className="p-4 md:p-5" onSubmit={handleSubmit(acceptNewUser)}>
                                <div className='grid gap-4 mb-4 grid-cols-2 m-4'>
                                    <div className='col-span-2 m-4'>
                                        <label className="block mb-2 text-sm font-medium text-gray-900 ">Email</label>
                                        <input type="text" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                                            {...register("email", {
                                                required: true, pattern: {
                                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                                    message: "Inserisci una mail valida",
                                                }
                                            })}
                                            placeholder="Email..." />
                                        <ErrorMessage errors={errors} name='email' render={({ message }) => <p className='text-red-500 text-sm w-[150px]'>{message}</p>} />
                                    </div>
                                    {/*<div className='col-span-2'>
                                        <label htmlFor='Password' className='block text-gray-700'>Password</label>
                                        <input type="password" placeholder="Password..." className='w-full p-2 border border-gray-300 rounded mt-1' {...register("password", {
                                            required: true, /*minLength: {
                                            value: 5,
                                        message: "min length is 5",
                                            }
                                        }
                                        )
                                        }></input>
                                    </div>*/}
                                    <div className="col-span-2 sm:col-span-1 m-4 mt-2">
                                        <label className="block mb-2 text-sm font-medium text-gray-900 ">Nome</label>
                                        <input type="text" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                                            {...register("name", {
                                                required: true
                                            })}
                                            placeholder="Nome utente" />
                                    </div>

                                    <div className="col-span-2 sm:col-span-1 m-4 mt-2">
                                        <label className="block mb-2 text-sm font-medium text-gray-900 ">Codice</label>
                                        <input type="text" id="code" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                                            {...register("code", {
                                                required: true, pattern: {
                                                    value: /^[a-zA-Z0-9]{6,}$/,
                                                    message: "Inserisci un codice di almeno 6 caratteri alfanumerici"
                                                }
                                            })}
                                            placeholder="Inserisci codice" />
                                        <ErrorMessage errors={errors} name='code' render={({ message }) => <p className='text-red-500 text-sm w-[150px]'>{message}</p>} />
                                    </div>


                                </div>
                                <div className='m-4 flex gap-x-2 items-center  justify-end '>

                                    <button className="px-4 py-2 bg-gray-500 hover:bg-gray-700 text-white  rounded" onClick={() => closeModalNewUser()}>Annulla</button>
                                    <button className="px-4 py-2 bg-rifiutando-green hover:bg-rifiutando-greenhover text-white rounded" type="submit">Conferma</button>

                                </div>
                            </form>



                        </div>

                    </div>)}
                {showDeleteModal && (
                    <div className='fixed w-full inset-0 flex items-center justify-center bg-black bg-opacity-50'>
                        <div className='bg-white p-4 rounded'>
                            <div className='flex items-start justify-between p-4 pb-1 border-b rounded-t'>
                                <h3 className='text-2xl font-bold mb-6 text-center'>
                                    Conferma cancellazione
                                </h3>
                            </div>
                            <div className='m-4'>
                                <p>Vuoi confermare la cancellazione dell'account associato a {selectedUser?.email}?</p>
                            </div>
                            <div className='m-4 flex gap-x-2 items-center  justify-end '>


                                <button className="px-4 py-2 bg-gray-500 hover:bg-gray-700 text-white rounded" onClick={() => closeModalDeleteUser()}>Annulla</button>
                                <button className="px-4 py-2 bg-red-500 hover:bg-red-700 text-white rounded" onClick={() => handleDeleteUser()}>Conferma</button>
                            </div>
                        </div>
                    </div>
                )

                }
            </div>
        </div>

    )
}

export default UsersPage