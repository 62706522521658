import React from 'react'
import LoginPage from '../../pages/auth/login.page'


const AuthLayout = () => {
  return (
    <>
      <LoginPage />
    </>
  )
}

export default AuthLayout