import { ErrorMessage } from '@hookform/error-message'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom'
import supabase from '../../utils/supabase';

interface SignupPasswordForm {
    email: string;
    password: string;
    code: string;
}


const SignUpPage = () => {

    const { register, handleSubmit, formState: { errors } } = useForm<SignupPasswordForm>();


    const handleSignUp: SubmitHandler<SignupPasswordForm> = async (data: { email: string, code: string, password: string }) => {
        
        try {
            const { data: supabasedata, error } = await supabase.from('adminusers').select("*").eq('email',data.email);
            if (error || supabasedata[0] === undefined) {
                //setError('email', { type: 'manual', message: error.message });
                alert("Utenza non trovata, controlla di aver inserito correttamente i dati");
                throw error;
            } else {
                if(supabasedata[0].code_firstaccess === data.code && supabasedata[0].is_firstaccess === false){
                    //console.log("Mail trovata", supabasedata)
                    signUpAfterCheck(data.email, data.password)
                }else{
                    alert("Il codice di attivazione non è corretto oppure è già stato usato")
                }
                        
            }
        }
        catch (error: any) {
            if(error !== null){
                console.error('Error message:', error.message);
            }
        }
            
    }

    const signUpAfterCheck = async (email: string, password: string) => {
        try{
            const { data: supabasedata, error } = await supabase.auth.signUp({
                email: email,
                password: password,
              })
            if (error) {
                //setError('email', { type: 'manual', message: error.message });
                alert("Il codice di attivazione non è corretto oppure è già stato usato")
                throw error;
            } else {
                alert("Utente attivato!");

            }

        }
        catch (error: any){
            if(error !== null){
                console.error('Error message:', error.message);
            }
        }
    }

    return (
        <div className='flex align-center justify-center min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8'>
            <div className='w-full max-w-md flex space-y-8 align-center flex-col justify-center'>
                <div className="group relative flex justify-center">
                    <button className="absolute left-0 inset-y-0 flex items-center pl-3">
                        <Link to="/auth/login"><i className="fa-solid fa-arrow-left h-7 w-7 text-grey-500 group-hover:text-grey-400"></i></Link>
                    </button>
                    <h2 className="text-2xl font-bold mb-6 text-center font-sans">
                        Sign Up
                    </h2>
                </div>
                <div>
                    <form onSubmit={handleSubmit(handleSignUp)}>
                        <div className='mb-4'>
                            <label htmlFor='Email' className='block text-gray-700 font-sans'>Email</label>
                            <input type="email" placeholder="Email..." className='w-full p-2 border border-gray-300 rounded mt-1 font-sans' {...register("email", {
                                required: true, pattern: {
                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                    message: "Entered value does not match email format",
                                }
                            })} />
                            <ErrorMessage errors={errors} name="email" render={({ message }) => <p className='text-red-500 text-sm'>{message}</p>} />
                        </div>
                        <div className='mb-4'>
                            <label htmlFor='Password' className='block text-gray-700 font-sans'>Password</label>
                            <input type="text" placeholder="Inserisci una nuova password" className='w-full p-2 border border-gray-300 rounded mt-1 font-sans' {...register("password", {
                                required: true, pattern: {
                                value: /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[-\#\$\.\%\&\*])(?=.*[a-zA-Z]).{6,}$/,
                                message: "Inserisci una password di almeno 6 caratteri contenente almeno un carattere maiuscolo, uno minuscolo, un numero e un carattere speciale tra $ % # * & - ."
                                }
                            })} />
                            <ErrorMessage errors={errors} name="password" render={({ message }) => <p className='text-red-500 text-sm'>{message}</p>} />
                        </div>


                        <div className='mb-4'>
                            <label htmlFor='Code' className='block text-gray-700 font-sans'>Codice</label>
                            <input type="text" placeholder="Codice..." className='w-full p-2 border border-gray-300 rounded mt-1 font-sans' {...register("code", {
                                required: true, pattern: {
                                    value: /^[a-zA-Z0-9]{6,}$/,
                                    message: "Inserisci un codice di almeno 6 caratteri alfanumerici"
                                }
                            })} />
                            <ErrorMessage errors={errors} name="code" render={({ message }) => <p className='text-red-500 text-sm'>{message}</p>} />
                        </div>

                        <div className='flex align-center justify-center w-full'>
                            <button type='submit' className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium font-sans rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'>
                                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                    <i className="fa-solid fa-key h-5 w-5 text-red-500 group-hover:text-red-400"></i>
                                </span>
                                Attiva
                            </button>
                        </div>
                    </form>
                </div>


            </div>
        </div>
    )
}

export default SignUpPage