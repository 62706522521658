import React from 'react'
import NavbarDashboard from '../../components/dashboard/navbar-dashboard'
import AccountsPage from '../../pages/dashboard/accounts.page'

const DashboardAccountLayout = () => {
  return (
  <div className='min-h-screen  bg-gray-50'>
    <NavbarDashboard/>
    <AccountsPage/>
    {/*<div className="container mx-auto p-8">
        <h1 className="text-3xl font-bold mb-6">Dashboard</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">

        <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-bold mb-2">Overview</h2>
            <p className="text-gray-700">Some overview content here...</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-bold mb-2">Statistics</h2>
            <p className="text-gray-700">Some statistics content here...</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-bold mb-2">Recent Activity</h2>
            <p className="text-gray-700">Some recent activity content here...</p>
          </div>
        </div>
        
    </div>*/}
  </div>
    
  )
}

export default DashboardAccountLayout