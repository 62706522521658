import React from 'react'

import ForgotPasswordPage from '../../pages/reset/forgot-password.page'




const ResetLayout = () => {
  return (
    <>
      <ForgotPasswordPage />
    </>
  )
}

export default ResetLayout