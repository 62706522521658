import React, { useEffect, useState } from 'react'
import supabase from '../../utils/supabase'
import { Link, useNavigate } from 'react-router-dom';


const NavbarDashboard: React.FC = () => {

  const [user, setUser] = useState<any | null>("");
  const navigate = useNavigate();



  useEffect(() => {

    const fetchData = async () => {
      try {
        const { data, error } = await supabase.auth.getUser();
        if (data) {
          setUser(data.user?.email);
        }
        else {
          throw error;
        }

      }
      catch (error) {
        console.log("Error admin user message:", error);
      }

    }

    fetchData();
  })


  const handleLogout = () => {
    supabase.auth.signOut()
    navigate('/auth/login');
  }

  return (
    <nav className='w-full fixed top-0 left-0 z-50 flex justify-between items-center  p-5 bg-rifiutando-green'>
      <div className='flex justify-between m-4 space-x-16'>
        <div className='text-2xl font-semibold text-white font-sans'>
          DASHBOARD
        </div>
        <div className='flex items-center justify-between m-2 gap-x-4'>
          <Link  to='/dashboard/accounts'><p className='text-white hover:underline font-sans'>Accounts</p></Link>
          <Link  to='/dashboard/users'><p className='text-white hover:underline font-sans'>Admin users</p></Link>
        </div>
      </div>

      <div className='flex justify-end items-center space-x-4'>
        <span className='text-md font-medium text-white font-sans'>
          Benvenuto {user}
        </span>
        {/*<a href="#" className="mx-2 hover:text-gray-400">HOME</a>
        <a href="#" className="mx-2 hover:text-gray-400">b</a>
        <a href="#" className="mx-2 hover:text-gray-400">c</a>
        <a href="#" className="mx-2 hover:text-gray-400">d</a>*/}
        <div>
        <button type="button" className='px-4 py-2  text-white rounded' onClick={handleLogout}>
          <i className="fa fa-sign-out" aria-hidden="true"></i>
        </button>
      </div>
      </div>
      
    </nav>
  )
}

export default NavbarDashboard