import React from 'react'
import NavbarDashboard from '../../components/dashboard/navbar-dashboard'
import UsersPage from '../../pages/dashboard/users.page'

const DashboardUsersLayout = () => {
  return (
    <div className='min-h-screen bg-gray-50 '>
      <NavbarDashboard/>
      <UsersPage/>
    </div>

  )
}

export default DashboardUsersLayout