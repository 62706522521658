import React from 'react'
import AuthGuard from './auth-guard'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AuthLayout from './layouts/auth/auth.layout';
import DashboardAccountLayout from './layouts/dashboard/dashboard-accounts.layout';
import ResetLayout from './layouts/reset/reset.layout';
import DashboardUsersLayout from './layouts/dashboard/dashboard-users.layout';
import NotFound from './layouts/notFound/not-found.layout';
import SignUpLayout from './layouts/signup/signup.layout';


interface RoutesConfig {
  path: string,
  element: React.ReactNode;
  children?: RoutesConfig[];
}

const routes: RoutesConfig[] = [
  {
    path: "/",
    element: <AuthLayout />
  },
  {
    path: "/auth/login",
    element: <AuthLayout />
  },
  {
    path: "/auth/reset-password",
    element: <ResetLayout />
  },
  {
    path: "/auth/signup",
    element: <SignUpLayout />
  },
  {
    path: "dashboard/accounts",
    element: (
      <AuthGuard>
        <DashboardAccountLayout />
      </AuthGuard>
    )

  },
  {
    path: "dashboard/users",
    element: (
      <AuthGuard>
        <DashboardUsersLayout />
      </AuthGuard>
    )
  }

]

const Routing: React.FC = () => {
  return (
    <Router>
      <Routes>

        {routes.map((route, id) => (

          <Route key={id} path={route.path} element={route.element}>
            {route.children && (route.children.map((childRoute, childId) => (
              <Route key={childId} path={childRoute.path} element={childRoute.element} />
              
            ))
            )}
          </Route>

        ))}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  )
}

export default Routing