import { ErrorMessage } from '@hookform/error-message';
import supabase from '../../utils/supabase';
import React from 'react'
import { SubmitHandler, useForm } from "react-hook-form";
import { Link } from 'react-router-dom';



interface ResetPasswordForm {
  email: string;
}

const ForgotPasswordPage: React.FC = () => {

  const { register, handleSubmit, setError, formState: { errors } } = useForm<ResetPasswordForm>();

  const handleResetPassword: SubmitHandler<ResetPasswordForm> = async (data: { email: string }) => {

    const { data: userData, error } = await supabase.from('adminusers').select('*').eq('email', data.email);
    if (error || userData.length === 0 ) {
      alert("Utente non trovato!");
    
    } else {

      try {
        const { error } = await supabase.auth.resetPasswordForEmail(data.email);
        if (error) {
          alert("Errore durante l'invio della mail");
          setError('email', { type: 'manual', message: error.message });
          throw error;
        } else {
          alert("Email inviata!");
        }
      }
      catch (error: any) {
        console.error('Error message:', error.message);
      }
    }

  }



  return (
    <div className='flex align-center justify-center min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8'>
      <div className='w-full max-w-md flex space-y-8 align-center flex-col justify-center'>
        <div className="group relative flex justify-center">
          <button className="absolute left-0 inset-y-0 flex items-center pl-3">
            <Link to="/auth/login"><i className="fa-solid fa-arrow-left h-7 w-7 text-grey-500 group-hover:text-grey-400"></i></Link>
          </button>
          <h2 className="text-2xl font-bold mb-6 text-center">
            Reset Password
          </h2>
        </div>
        <div>
          <form onSubmit={handleSubmit(handleResetPassword)}>
            <div className='mb-4'>
              <label htmlFor='Email' className='block text-gray-700'>Email</label>
              <input type="email" placeholder="Email..." className='w-full p-2 border border-gray-300 rounded mt-1' {...register("email", {
                required: true, pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: "Entered value does not match email format",
                }
              })} />
              <ErrorMessage errors={errors} name="email" render={({ message }) => <p className='text-red-500 text-sm'>{message}</p>} />
            </div>

            <div className='flex align-center justify-center w-full'>
              <button type='submit' className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'>
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <i className="fa-solid fa-key h-5 w-5 text-blue-500 group-hover:text-blue-400"></i>
                </span>
                Reset Password

              </button>
            </div>
          </form>
        </div>


      </div>
    </div>
  )
}

export default ForgotPasswordPage