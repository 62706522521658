import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <div className="text-center mt-10">
          <h1 className='text-5xl text-[#333]'>404 - Pagina non trovata</h1>
          <p className='text-xl text-[#666]'>La pagina che stai cercando non esiste.</p>
          <Link to="/dashboard/accounts" className='display-block mt-5 text-md text-[#007bff] hover:decoration-solid'>Torna alla Home</Link>
          
        </div>
    )
}

export default NotFound