import React, { Fragment, useEffect, useState } from 'react'

import { useLocation, Navigate } from 'react-router-dom';

import supabase from './utils/supabase';

interface AuthGuardProp {
  children: React.ReactNode
}



const AuthGuard: React.FC<AuthGuardProp> = ({ children }) => {

  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<any>(null);

  useEffect(() => {
    

    getUser();
  }, []); // Esegui solo al montaggio del componente

  const getUser = async () => {
    try {

      const { data, error } = await supabase.auth.getUser();
      if (error) {
        console.error('Errore nel recuperare l\'utente:', error.message);
        setIsAuthenticated(false);
      } else {

        setIsAuthenticated(!!data.user);
      }
    } catch (error) {
      console.error('Errore nella chiamata a getUser:', error);
      setIsAuthenticated(false);
    }
  };


  if (isAuthenticated === null) {

    // Mostra un caricamento mentre l'utente è in fase di recupero
    return <div>Loading...</div>;
  }


  if (!isAuthenticated) {

    return <Navigate to="/auth/login" />;
  }

  if (requestedLocation && location !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <Fragment>{children}</Fragment>;

}

export default AuthGuard