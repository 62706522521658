import supabase from '../../utils/supabase';
import React, { useCallback, useEffect, useState } from 'react'
import dayjs from 'dayjs';
import _ from 'lodash'


interface Account {
  id: number,
  created_at: string,
  user_id: string,
  activation_code: string,
  code_expire_at: string,
  device_id: string,
  email: string

}

const AccountsPage = () => {

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [showModal, setShowModal] = useState<boolean>(false)


  const debouncedFetchAccounts = useCallback(
    _.debounce((currentFilter,currentPage, currentPageSize, currentSortColumn, currentSortOrder) => {
        fetchData(currentFilter,currentPage, currentPageSize, currentSortColumn, currentSortOrder);
        
    }, 500),
    []
);


  useEffect(() => {

    //console.log("page", pageSize);
    fetchCount(filter);
    debouncedFetchAccounts(filter,page, pageSize, sortColumn, sortOrder);
    


  }, [page, pageSize, filter, sortColumn, sortOrder, debouncedFetchAccounts, ])

  const handleSort = (column : string) => {
    const order = column === sortColumn && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortOrder(order);
  };

  const fetchCount = async (filterData: string) => {
    //console.log("count")
    const { count, error } = await supabase.from('accounts')
                                          .select('*', { count: 'exact', head: true })
                                          .ilike('email', `%${filterData}%`)
                                          
    if (error) {
      console.error(error)
    } else if (count !== null) {
      
      setTotalRecords(count)
    }
  }

  const fetchData = async (filterData: string, pageData: number, pageSizeData: number, currentSortColumn: string, currentSortOrder: string) => {
    //console.log("fetch");
    const sortColumn = currentSortColumn || 'id';
    //const cacheKey = `${filter}-${page}`
    try {

      const { data, error } = await supabase.from('accounts')
                                            .select('*')
                                            .ilike('email', `%${filterData}%`)
                                            .range((pageData - 1) * pageSizeData, pageData * pageSizeData - 1)
                                            .order(sortColumn, {ascending: currentSortOrder === 'asc'});

      // pezzo da aggiungere alla query per settare filtri: .ilike('email', '%${filter}%').range((page - 1) * pageSize, page * pageSize -1);
      //if(cachedData[cacheKey]){
      //console.log("Valori dalla cache", cachedData[cacheKey])
      //setAccounts(cachedData[cacheKey])
      //return
      //}

      if (data) {
        setAccounts(data);

        return
      }
      else {
        console.log("Dati degli account non presenti")
        throw error;
      }

    }
    catch (error) {
      console.log("Error accounts message:", error);
    }
  }



  const handleActivateLicense = async () => {
    if (!selectedAccount)
      return;

    const licenseKey = ('000000' + (Math.floor(Math.random() * 1000000).toString())).slice(-6);
    const expiryDate = dayjs().add(1, 'year').format('YYYY-MM-DD');


    const { data, error } = await supabase.from('accounts').update({ activation_code: licenseKey, code_expire_at: expiryDate }).eq('id', selectedAccount.id).select()
    if (error) {
      console.log(error);
      alert("Errore durante l'update")
    }
    else {
      console.log("data update", data);
      fetchData(filter,page, pageSize, sortColumn, sortOrder);
    }
    setShowModal(false);

  }


  const confirmActivation = (account: Account) => {
    setSelectedAccount(account)
    setShowModal(true)
  }

  const cancelConfirmation = () => {
    setSelectedAccount(null);
    setShowModal(false);
  }

  const totalPages = (totalRecords === 0) ? 1 : Math.ceil(totalRecords / pageSize)

  return (
    <div className='relative pt-24 shadow-md sm:rounded-lg w-full'>

      <div className="flex items-center justify-between flex-col border border-stroke bg-gray-50 px-5 pt-6 pb-2.5 shadow-default sm:px-7.5 xl:pb-1 w-full ">
        <div className='flex justify-between  mt-4 w-full px-4 container'>
          <div className=''>
            <input
              type="text"
              placeholder="Email..."
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className="px-4 py-2 border rounded w-full"
            />

          </div>


        </div>

        <div className="w-full overflow-x overflow-y-auto container">
          <table className='table-auto overflow-scroll min-w-full max-w-full text-sm text-left rtl:text-right text-gray-500 mb-12 '>
            <thead className='text-md uppercase text-gray-700 bg-gray-50 sticky top-0 z-10'>
              <tr >
                <th scope='col' className="px-6 py-3 font-sans cursor-pointer" onClick={() => handleSort('id')}> {sortColumn === 'id' && (sortOrder === 'asc' ? '↑' : '↓')} id</th>
                <th scope='col' className="px-6 py-3 font-sans cursor-pointer" onClick={() => handleSort('user_id')}> {sortColumn === 'user_id' && (sortOrder === 'asc' ? '↑' : '↓')}user_id</th>
                <th scope='col' className="px-6 py-3 font-sans cursor-pointer" onClick={() => handleSort('created_at')}> {sortColumn === 'created_at' && (sortOrder === 'asc' ? '↑' : '↓')} created_at</th>
                <th scope='col' className="px-6 py-3 font-sans cursor-pointer" onClick={() => handleSort('created_at')}> {sortColumn === 'created_at' && (sortOrder === 'asc' ? '↑' : '↓')} activation_code</th>
                <th scope='col' className="px-6 py-3 font-sans cursor-pointer" onClick={() => handleSort('code_expire_at')}>{sortColumn === 'code_expire_at' && (sortOrder === 'asc' ? '↑' : '↓')} code_expire_at</th>
                <th scope='col' className="px-6 py-3 font-sans cursor-pointer" onClick={() => handleSort('email')}> {sortColumn === 'email' && (sortOrder === 'asc' ? '↑' : '↓')} email</th>
                <th scope='col' className="px-6 py-3 font-sans">activate</th>
              </tr>
            </thead>
            <tbody>
              {accounts.map((account) => {

                const isActive = dayjs(account.code_expire_at).isAfter(dayjs());

                return (

                  <tr key={account.id} className="bg-white hover:bg-gray-100 border-b ">
                    <td className="px-6 py-4 font-sans"  onClick={() => handleSort('id')}>{account.id}</td>
                    <td className="font-medium font-sans text-gray-900 whitespace-nowrap px-6 py-4">{account.user_id}</td>
                    <td className="px-6 py-4 font-sans">{account.created_at}</td>
                    <td className="px-6 py-4 font-sans">{account.activation_code}</td>
                    <td className="px-6 py-4 font-sans">{account.code_expire_at}</td>
                    <td className="px-6 py-4 font-sans">{account.email}</td>
                    <td className="px-6 py-4"><button disabled={isActive} type='button' onClick={() => confirmActivation(account)} className={`px-4 py-2 text-white  font-sans rounded ${isActive ? 'bg-rifiutando-disabled' : 'bg-rifiutando-green hover:bg-rifiutando-greenhover'}`}>{isActive ? "Attivato" : "Attiva" }</button></td>
                  </tr>
                )
              }
              )}
            </tbody>
          </table>

        </div>
        <div className='flex justify-between items-center mt-4 mb-4 w-full px-4 container'>
          <div className='flex items-center '>
            <span className='mr-2  font-sans'>Righe per pagina:</span>
            <select value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))} className="px-4 py-2 border rounded">
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <div className='flex items-center gap-x-2'>
            <button className="px-4 py-2 bg-gray-300 rounded  font-sans" onClick={() => setPage(page - 1)} disabled={page === 1}>Precedente</button>
            <span className=' font-sans'>Pagina {page} di {totalPages}</span>
            <button className="px-4 py-2  font-sans bg-gray-300 rounded" onClick={() => setPage(page + 1)} disabled={page >= totalPages} >Successivo</button>
          </div>
        </div>
        {showModal && (
          <div className='fixed w-full inset-0 flex items-center justify-center bg-black bg-opacity-50'>
            <div className='bg-white p-4 rounded'>
              {/*Modal header*/}
              <div className='flex items-start justify-between p-4 pb-1 border-b rounded-t'>
                <h3 className='text-2xl font-bold mb-6 text-center  font-sans'>
                  Conferma attivazione
                </h3>
              </div>
              <div className='m-4'>
                <p className=' font-sans'>Vuoi confermare l'attivazione della licenza per l'account {selectedAccount?.email}?</p>
              </div>
              <div className='m-4 flex gap-x-2 items-center  justify-end '>


                <button className="px-4 py-2 bg-gray-500 hover:bg-gray-700 text-white  font-sans rounded" onClick={() => cancelConfirmation()}>Annulla</button>
                <button className="px-4 py-2 bg-rifiutando-green hover:bg-rifiutando-greenhover text-white  font-sans rounded" onClick={() => handleActivateLicense()}>Conferma</button>
              </div>


            </div>

          </div>)}
      </div>
    </div>
  )
}

export default AccountsPage