import React, { useState } from 'react'
import supabase from '../../utils/supabase';
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, Link } from 'react-router-dom';
import { ErrorMessage } from '@hookform/error-message'
import '@fortawesome/fontawesome-free/css/all.min.css';



interface LoginFormInputs {
  email: string;
  password: string;
}


const LoginPage: React.FC = () => {

  const navigate = useNavigate();
  const { register, handleSubmit, setError, formState: { errors } } = useForm<LoginFormInputs>(); //register è una callback function che salva gli input nell'hook e ritorna alcune props, 



  const handleLogin: SubmitHandler<LoginFormInputs> = async (data: { email: string, password: string }) => {
    try {
      const { data: publicData, error: publicError } = await supabase.from('adminusers').select("*").eq('email', data.email);
      if (publicError || publicData.length === 0 ) {
        alert("Utente non trovato");

      } else {

        const { data: authData, error } = await supabase.auth.signInWithPassword({ email: data.email, password: data.password }); //chiamata asincrona di supabase per accedere con password
        if (error) {


          setError('password', { type: 'manual', message: error.message });
          throw error;
        }
        if (authData.user) {
          const { data, error } = await supabase.auth.getSession();

          navigate('/dashboard/accounts');
        }
      }
    }
    catch (error: any) {
      console.error('Error message', error.message);
    }

  }
  return (
    <div className='flex align-center justify-center min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8'>
      <div className='w-full max-w-md flex space-y-8 align-center flex-col justify-center'>
        <div>
          <h2 className="text-2xl font-bold mb-6 text-center font-sans">
            Autenticazione
          </h2>
        </div>
        <div>
          <form onSubmit={handleSubmit(handleLogin)}>
            <div className='mb-4'>
              <label htmlFor='Email' className='block text-gray-700 font-sans'>Email</label>
              <input type="email" placeholder="Email..." className='w-full p-2 border border-gray-300 rounded mt-1 font-sans' {...register("email", {
                required: true, pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: "Entered value does not match email format",
                }
              })} />

            </div>
            <div className='mb-4'>
              <label htmlFor='Password' className='block text-gray-700 font-sans'>Password</label>
              <input type="password" placeholder="Password..." className='w-full p-2 border border-gray-300 rounded mt-1 font-sans' {...register("password", {
                required: true, /*minLength: {
            value: 5,
            message: "min length is 5",
          }*/
              }
              )
              }></input>
              <ErrorMessage errors={errors} name="password" render={({ message }) => <p className='text-red-500 text-sm'>{message}</p>} />

            </div>
            <div className='flex items-center justify-center w-full'>
              <button type='submit' className='group relative w-full flex items-center justify-center py-2 px-4 border border-transparent text-sm font-medium font-sans rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <i className="fa-solid fa-lock h-5 w-5 text-indigo-500 group-hover:text-indigo-400"></i>
                </span>
                Login

              </button>
            </div>

          </form>
        </div>
        <div className="mt-4 text-center">
          <Link to="/auth/signup" className="text-blue-500 hover:text-blue-700 font-sans">Registrazione</Link>
        </div>
        <div className="mt-2 text-center">
          <Link to="/auth/reset-password" className="text-blue-500 hover:text-blue-700 font-sans">Password dimenticata?</Link>
        </div>


      </div>
    </div>
  )
}

export default LoginPage